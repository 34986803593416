import React from 'react';
import { Route } from 'react-router-dom';
import { Provider } from './context';
import Layout from "./components/layout";

import Adminpage from './components/adminpage';
import Counterpage from './components/counterpage';
import Searchpage from './components/searchpage';
import Startpage from './components/startpage';
import Datapage from './components/datapage';
import Footnotepage from './components/footnotepage';
import Loginpage from './components/loginpage';
import Redirectpage from './components/loginpage/redirect';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./scss/styles.scss";

function App() {
  return (
    <Provider>
      <div className="App">
        <div>
          <Layout>
            <Route exact path='/' component={Startpage} />
            <Route exact path='/admin' component={Adminpage} />
            <Route exact path='/data' component={Datapage} />
            <Route exact path='/hakusivu' component={Searchpage} />
            <Route exact path='/laskuri' component={Counterpage} />
            <Route exact path='/lisatieto' component={Footnotepage} />
            <Route exact path='/login' component={Loginpage} />
            <Route exact path='/redirect' component={Redirectpage} />
          </Layout>
        </div>
      </div>
    </Provider>
  );
}

export default App;