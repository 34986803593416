import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

// Get language from state in localstorage or default to finnish
const initLanguage = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')).selectedLanguage : "fi";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //load: 'languageOnly',
    lng: initLanguage,
    fallbackLng: 'fi',
    //debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
});

export default i18n;